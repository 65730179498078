@import "~antd/lib/style/color/colors";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&display=swap");

@primary-color: @green-7;
@font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.mw-800 {
  max-width: 1200px !important;
}
th.ant-table-cell {
  color: @blue-7!important;
}
.table-shadow {
  border: 1px solid #eee;
  background-color: #fafafa;
  padding: 15px;
}
.form-border {
  border: 1px solid #eee;
  padding: 15px;
  background-color: #fafafa;
}

.font-merri {
  font-family: "Georama", serif;
}
.fw-900 {
  font-weight: 900 !important;
}
