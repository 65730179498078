@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "./Tema.less";

.anticon svg {
  display: block;
}
.site-layout-content {
  min-height: 280px;
  background: #fff;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-layout-header {
  padding: 0 5px !important;
}
.ant-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 36px !important;
}
.ant-menu-item-selected {
  font-weight: 700;
  background-color: transparent !important;
  color: @green-7;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.ant-drawer-body {
  padding: 0;
}
.main-menum.ant-menu-horizontal {
  justify-content: center !important;
  line-height: 36px !important;
  margin-left: -15px;
  margin-right: -15px;
  background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
}
.main-menum.ant-menu-horizontal span.ant-menu-title-content {
  color: white !important;
}
.desktop-sider {
  margin-top: 60px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: white !important;
  min-height: calc(100vh - 136px);
}
.ant-layout-sider .ant-menu-item-selected {
  border-top: 1px solid #a7cfa9;
  border-bottom: 1px solid #a7cfa9;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #f7f9fa;
}

.defMenuCard .ant-card-head-title {
  color: @blue-6;
  font-weight: 600;
}
.defMenuCard {
  box-shadow: 0px 0px 5px #f5f7f9;
  border-color: #eee;
}
.ant-page-header-back-button {
  border: 1px solid rgb(217, 217, 217) !important;
  padding: 5px 15px !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}
.ant-layout-header {
  height: unset !important;
}
.PhoneInputInput {
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 3px;
}
.PhoneInputInput::placeholder {
  color: #bfbfbf;
}
.form-bg {
  padding: 15px 10px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}
.imaj {
  border: 3px solid white;
  box-shadow: 0 0 5px #ccc;
}
.bimages {
  width: 100%;
  max-width: 160px;
}
.brow {
  border: 1px solid @orange-3;
  box-shadow: 0 0 5px #ddd;
  padding: 10px;
}
.ql-editor {
  min-height: 250px;
  background-color: white;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: @red-5;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: @red-7;
}
.ant-upload {
  background-color: white !important;
}
.gallery-image {
  border: 3px solid white;
  box-shadow: 0 0 5px #eee;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login-form {
  width: 320px;
  max-width: "95%";
}
.login-form-container {
  padding: 20px 20px 0 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 1px 1px 10px #333;
}
.login-logo {
  width: 200px;
  margin-left: calc(100% / 2 - 100px);
}
.Demo__some-network {
  display: inline;
  margin-right: 3px;
}
.react-share__ShareButton svg {
  border-radius: 3px !important;
}
.stats-div {
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  padding: 20px;
  background-color: white !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1300px) {
  .container {
    width: 1250px;
  }
}

.checkout-desk.ant-descriptions-item-content {
  border-top: none !important;
}
.checkout-desk.ant-descriptions-item-label {
  border-bottom: none !important;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.fix-title .ant-descriptions-title {
  padding: 10px 0 0 10px !important;
}
.ant-card-meta-title {
  white-space: pre-line;
}
.kard:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}
.kart .ant-card-actions {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.contact-form {
  border: 1px solid #ddd;
  background-color: #fafafa;
  padding: 20px;
}
.stat-card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  width: 100%;
}
.donat-box {
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: 15px;
}
.ant-image-preview-img {
  display: initial !important;
}
.kolonon {
}
.kolonoff {
  display: none;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.interval-button.ant-radio-button-wrapper-checked {
  border-width: 2px !important;
  border-radius: 2px;
}
.price-button.ant-btn-primary {
  background-color: white;
  color: @orange-7;
  font-weight: bold;
  border: 2px solid @orange-7;
  text-shadow: none;
}

.header-class {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

div.is-disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: pointer !important;
}
.admin-menu {
  width: 720px;
}
.admin-menum {
  background-color: transparent !important;
  margin-top: 10px;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #c26c21 !important;
  border: 2px solid #a35c1d;
  border-radius: 5px;
}

.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-title-content {
  color: white !important;
  font-size: 15px !important;
}
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-title-content:hover {
  font-weight: bold !important;
  color: white !important;
}
